<template>
  <svg
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
  >
    <path
      d="M39 13.8284L35.4142 17.4142C34.6332 18.1953 33.3668 18.1953 32.5858 17.4142C31.8047 16.6332 31.8047 15.3668 32.5858 14.5858L36.1716 11L32.5858 7.41421C31.8047 6.63316 31.8047 5.36683 32.5858 4.58579C33.3668 3.80474 34.6332 3.80474 35.4142 4.58579L39 8.17157L42.5858 4.58579C43.3668 3.80474 44.6332 3.80474 45.4142 4.58579C46.1953 5.36683 46.1953 6.63316 45.4142 7.41421L41.8284 11L45.4142 14.5858C46.1953 15.3668 46.1953 16.6332 45.4142 17.4142C44.6332 18.1953 43.3668 18.1953 42.5858 17.4142L39 13.8284Z"
    />
    <path
      d="M3.75736 5.75736C4.88258 4.63214 6.4087 4 8 4H26C27.1046 4 28 4.89543 28 6C28 7.10457 27.1046 8 26 8H8C7.46957 8 6.96086 8.21071 6.58579 8.58579C6.21071 8.96086 6 9.46957 6 10V30C6 30.5304 6.21071 31.0391 6.58579 31.4142C6.96086 31.7893 7.46957 32 8 32H40C40.5304 32 41.0391 31.7893 41.4142 31.4142C41.7893 31.0391 42 30.5304 42 30V24C42 22.8954 42.8954 22 44 22C45.1046 22 46 22.8954 46 24V30C46 31.5913 45.3679 33.1174 44.2426 34.2426C43.1174 35.3679 41.5913 36 40 36H26V40H32C33.1046 40 34 40.8954 34 42C34 43.1046 33.1046 44 32 44H16C14.8954 44 14 43.1046 14 42C14 40.8954 14.8954 40 16 40H22V36H8C6.4087 36 4.88258 35.3679 3.75736 34.2426C2.63214 33.1174 2 31.5913 2 30V10C2 8.4087 2.63214 6.88258 3.75736 5.75736Z"
    />
  </svg>
</template>
